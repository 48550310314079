<template>
  <div class="page">
    <!-- 页面头部 包含面包屑 -->
    <PageHead />

    <div class="page-body">
      <!-- 筛选 -->
      <Spin :loading="loading">
        <!-- <div class="filter-menu-bar flex">
          <div
            class="filter-button flex justify-center hover-hand line-1"
            :class="item.active ? 'filter-button-active' : ''"
            v-for="(item, index) in category"
            :key="index"
            @click="changeFilter(index, item.type)"
          >
            {{ item.title }}
          </div>
        </div> -->
        <div class="top-tab-box">
          <div class="tab-list">
            <div
              :class="['tab-item', active == index ? 'active' : '']"
              v-for="(item, index) in tabs"
              :key="index"
              @click="changeFilter(index)"
            >
              <img
                class="img"
                :src="active == index ? item.iconChoose : item.icon"
              />
              <div class="text-center">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <Map
          type="markers"
          :center="map_data.center"
          :zoom="map_data.zoom"
          :height="map_data.height"
          :markers="map_data.markers"
          :locateName="map_data.locateName"
        />
      </Spin>

      <!-- <div class="filter-bar flex">
        <div
          class="filter-button flex align-center hover-hand"
          :class="item.active ? 'filter-button-active' : ''"
          v-for="(item, index) in category"
          :key="index"
          @click="changeFilter(index, item.id)"
        >
          {{ item.title }}
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import PageHead from "@/components/page-header/page-head";
import Map from "@/components/bmap/bmap";
import Spin from "@/components/spin/spin";
import { mapState } from "vuex";

// 所有tabs分类
let tabsAllList = [
  {
    title: "景区",
    type: "scenic",
    iconChoose: require("@/assets/icon/tabs/jd.png"),
    img: require("@/assets/icon/cover/jd.png"),
    icon: require("@/assets/icon/tabs/jd_choose.png"),
    url: "Scenic_query",
    router: "/pagesScenic/index/detail",
  },
  {
    title: "景点",
    type: "spot",
    iconChoose: require("@/assets/icon/tabs/jd.png"),
    icon: require("@/assets/icon/tabs/jd_choose.png"),
    img: require("@/assets/icon/cover/jd.png"),
    imgChoose: require("@/assets/icon/cover/jd_choose.png"),
    url: "ScenicSpot_query",
    router: "/pagesScenic/spot/detail",
  },
  {
    title: "餐饮",
    type: "restaurant",
    iconChoose: require("@/assets/icon/tabs/cy.png"),
    icon: require("@/assets/icon/tabs/cy_choose.png"),
    img: require("@/assets/icon/cover/cy.png"),
    imgChoose: require("@/assets/icon/cover/cy_choose.png"),
    url: "Restaurant_query",
    router: "/pagesScenic/food/detail",
  },
  {
    title: "酒店",
    type: "hotel",
    iconChoose: require("@/assets/icon/tabs/ms.png"),
    icon: require("@/assets/icon/tabs/ms_choose.png"),
    img: require("@/assets/icon/cover/ms.png"),
    imgChoose: require("@/assets/icon/cover/ms_choose.png"),
    url: "Hotel_query",
    router: "/pagesScenic/hotel/detail",
  },
  {
    title: "厕所",
    type: "facility",
    iconChoose: require("@/assets/icon/tabs/cs.png"),
    icon: require("@/assets/icon/tabs/cs_choose.png"),
    img: require("@/assets/icon/cover/cs.png"),
    imgChoose: require("@/assets/icon/cover/cs_choose.png"),
    url: "Facility_query",
    router: "/pagesScenic/facility/detail",
  },
  {
    title: "加油站",
    type: "facility",
    iconChoose: require("@/assets/icon/tabs/jyz.png"),
    icon: require("@/assets/icon/tabs/jyz_choose.png"),
    img: require("@/assets/icon/cover/jyz.png"),
    imgChoose: require("@/assets/icon/cover/jyz.png"),
    url: "Facility_query",
    router: "/pagesScenic/facility/detail",
  },
  {
    title: "停车场",
    type: "facility",
    iconChoose: require("@/assets/icon/tabs/park.png"),
    icon: require("@/assets/icon/tabs/park_choose.png"),
    img: require("@/assets/icon/cover/park.png"),
    imgChoose: require("@/assets/icon/cover/park.png"),
    url: "Facility_query",
    router: "/pagesScenic/facility/detail",
  },
  {
    title: "游客中心",
    type: "facility",
    iconChoose: require("@/assets/icon/tabs/ykzx.png"),
    img: require("@/assets/icon/cover/ykzx.png"),
    icon: require("@/assets/icon/tabs/ykzx_choose.png"),
    url: "Facility_query",
    router: "/pagesScenic/facility/detail",
  },
  {
    title: "公共设施",
    type: "facility",
    iconChoose: require("@/assets/icon/tabs/sheshi.png"),
    img: require("@/assets/icon/cover/sheshi.png"),
    icon: require("@/assets/icon/tabs/sheshi_choose.png"),
    url: "Facility_query",
    router: "/pagesScenic/facility/detail",
  },
];
export default {
  name: "ScenicGuided",
  components: {
    PageHead,
    Map,
    Spin,
  },
  data() {
    return {
      map_data: {},
      mapShow: {},
      category: [],
      loading: true, //loading
      active: 0,
      tabs: [
        {
          title: "景点",
          type: "spot",
          iconChoose: require("@/assets/icon/tabs/jd.png"),
          icon: require("@/assets/icon/tabs/jd_choose.png"),
          img: require("@/assets/icon/cover/jd_choose.png"),
          imgChoose: require("@/assets/icon/cover/jd_choose.png"),
          url: "ScenicSpot_query",
          router: "/pagesScenic/spot/detail",
        },
        {
          title: "餐饮",
          type: "restaurant",
          iconChoose: require("@/assets/icon/tabs/cy.png"),
          icon: require("@/assets/icon/tabs/cy_choose.png"),
          img: require("@/assets/icon/cover/cy.png"),
          imgChoose: require("@/assets/icon/cover/cy_choose.png"),
          url: "Restaurant_query",
          router: "/pagesScenic/food/detail",
        },
        {
          title: "酒店",
          type: "hotel",
          iconChoose: require("@/assets/icon/tabs/ms.png"),
          icon: require("@/assets/icon/tabs/ms_choose.png"),
          img: require("@/assets/icon/cover/ms.png"),
          imgChoose: require("@/assets/icon/cover/ms_choose.png"),
          url: "Hotel_query",
          router: "/pagesScenic/hotel/detail",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      settingLang: (state) => state.lang,
    }),
  },
  created() {
    this.initMapData();
    this.getMapData();
  },
  methods: {
    changeFilter(index) {
      this.active = index;
      this.getMapData();
      //重置分页器
      // this.$refs.shopCardList.reset();
    },
    async initMapData() {
      let res = await this.$http.FacilityCategory_all();
      tabsAllList.map((e, index) => {
        let i = res.data.findIndex((item) => item.name === e.title);
        if (i > -1) {
          let tabsItem = {
            ...e,
            category_id: res.data[i].id,
          };
          this.tabs.push(tabsItem);
        }
      });
    },
    async getMapData() {
      let res = await this.$http[this.tabs[this.active].url]({
        pageSize: 300,
        pageNo: 1,
        category_id: this.tabs[this.active].category_id || null,
      });
      let mapShow = res.data.filter((item) => item.map_lng && item.map_lat);

      let markers = mapShow.map((item) => {
        return {
          id: item.id,
          type: this.tabs[this.active].type,
          lng: Number(item.map_lng),
          lat: Number(item.map_lat),
          title: item[`${this.tabs[this.active].type}_name`],
          iconUrl: this.tabs[this.active].img,
          pic:
            (this.tabs[this.active].type == "spot"
              ? item[`scenic_spot_files`][0]?.url
              : item[`${this.tabs[this.active].type}_files`][0]?.url) || "",
          description: item.description,
        };
      });
      this.map_data = {
        height: 600, //地图高度
        center: {
          //中心点
          lng: mapShow[0].map_lng,
          lat: mapShow[0].map_lat,
        },
        zoom: 16,
        markers: markers,
        locateName: "",
      };
      this.loading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  font-weight: 400;
  background: #f5f5f5;
}
.top-tab-box {
  position: absolute;
  top: 40px;
  right: 25px;
  box-sizing: border-box;
  z-index: 2;

  .tab-list {
    // max-height: 74vh;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    // height: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(209, 209, 209, 0.8);
    border-radius: 3px;

    .tab-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      // width: 100rpx;
      width: 100%;
      color: #212121;
      border-width: 25px;
      border-bottom: 1px solid #e4e4e4;
      padding: 5px;

      .img {
        // width: 50rpx;
        // height: 50rpx;
        width: 20px;
        height: 20px;
      }
    }

    .active {
      color: #ffffff;
      background: #22915b;
    }
  }
}
.page-body {
  padding: 2rem 18.75%;

  //第二套选项卡
  .filter-bar {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid @primary-color;
    .filter-button {
      margin-right: 1rem;
      border: 1px solid @primary-color;
      border-radius: 2rem;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: @primary-color;
      padding: 0.1rem 1rem;
      &:hover {
        background: @primary-color;
        color: #fff;
      }
    }
    .filter-button-active {
      background: @primary-color;
      color: #fff;
    }
  }

  .filter-menu-bar {
    // padding-bottom: 1.5rem;
    margin-bottom: 2rem;
    width: 100%;
    .filter-button {
      flex: 1;
      border: 1px solid @primary-color;
      border-right: 0;
      // border-radius: 2rem;
      font-size: 1rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: @primary-color;
      padding: 0.5rem 0.2rem;
      line-height: 2rem;
      width: 100%;
      text-align: center;
      &:first-child {
        border-radius: 2rem 0 0 2rem;
      }
      &:last-child {
        border-right: 1px solid @primary-color;
        border-radius: 0 2rem 2rem 0;
      }
      &:hover {
        background: @primary-color;
        color: #fff;
      }
    }
    .filter-button-active {
      background: @primary-color;
      color: #fff;
    }
  }
}
</style>
