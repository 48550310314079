<template>
<div style="width:100%">
  <a-spin :tip="tip" :spinning="loading" :delay="delayTime">
    <div :class="loading?'spin-content':''">
      <slot></slot>
    </div>
  </a-spin>
</div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  props: {
    loading: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      delayTime: 500,
    };
  },
  computed: {
    ...mapState({
      settingLang: (state) => state.lang,
    }),
    tip(){
      return this.settingLang === 'zh'?'加载中...':'Loading...'
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.spin-content {
  border: 0px solid rgba(60, 145, 91, 0.5);
  background: rgba(255, 255, 255, 0.2);
  // padding: 0.5rem;
  min-height: 120px;
}
:deep(.ant-spin-text){
  font-size: 1.2rem;
  font-weight: bold;
}
:deep(.ant-spin-dot){
  width: 25px;
  height: 25px;
  .ant-spin-dot-item{
    width: 12px;
    height: 12px;
  }
} 
</style>
